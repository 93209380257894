import React from "react";
import theme from "theme";
import { Theme, Link, Image, LinkBox, Box, Section, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Orion for Space Data
			</title>
			<meta name={"description"} content={"Orion for Space Data"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/64d69053477c3a0019b68466/images/Logo.png?v=2023-08-11T21:30:55.901Z"} type={"image/x-icon"} />
		</Helmet>
		<Section
			sm-padding="8px 0 8px 0"
			quarkly-title="Header-2"
			background="#EBFAFE"
			height="230px"
			sm-align-content="center"
			sm-justify-content="center"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				justify-content="space-between"
				align-items="center"
				lg-flex-direction="row"
				sm-justify-content="center"
			/>
			<Box
				display="flex"
				padding="12px 0 18px 0"
				justify-content="center"
				align-items="flex-start"
				flex-direction="row"
				width="100%"
				sm-width="50%"
				sm-align-items="center"
				sm-flex-direction="row"
				sm-justify-content="center"
				md-width="50%"
				lg-width="70%"
				md-justify-content="flex-start"
				lg-justify-content="flex-start"
				sm-align-self="center"
			>
				<LinkBox
					flex-direction="row"
					href="/index"
					display="flex"
					grid-gap="18px"
					sm-align-self="auto"
					sm-align-content="flex-start"
				>
					<Image
						src="https://uploads.quarkly.io/64d69053477c3a0019b68466/images/Logo.png?v=2023-08-11T21:30:55.901Z"
						display="block"
						width="300PX"
						sm-position="static"
						sm-align-self="auto"
						sm-order="0"
					/>
				</LinkBox>
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="--color-lightD1"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
			mix-blend-mode="normal"
			transition="all 0s ease 0.2s"
			opacity="1"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--darkL1"
					font="normal normal 700 42px/1.2 --fontFamily-googleMontserrat"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					From Satellite to Insight
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="--darkL1"
					font="normal 900 72px/1.2 --fontFamily-googleRoboto"
					lg-text-align="center"
					text-align="center"
				>
					Coming Soon!
				</Text>
			</Box>
		</Section>
		<Section padding="50px 0 50px 0" quarkly-title="Footer-2" background="--color-darkL1">
			<Box display="flex" sm-flex-direction="column" align-content="flex-start" justify-content="center">
				<Text
					margin="0px 0px 0px 0px"
					font="normal 300 16px/1.5 --fontFamily-googleMontserrat"
					color="--lightD1"
					sm-margin="0px 0px 15px 0px"
					sm-text-align="center"
					align-self="flex-start"
					order="0"
					display="block"
					text-align="left"
					position="relative"
				>
					© 2023 Orion for Space Data, Ltd. All rights reserved.
				</Text>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"64d69053477c3a0019b68464"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});