export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"64d69053477c3a0019b6846e",
				"64d69053477c3a0019b6846b"
			]
		},
		"64d69053477c3a0019b6846b": {
			"id": "64d69053477c3a0019b6846b",
			"name": "404",
			"pageUrl": "404"
		},
		"64d69053477c3a0019b6846e": {
			"id": "64d69053477c3a0019b6846e",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"favicon_32px": "https://uploads.quarkly.io/64d69053477c3a0019b68466/images/Logo.png?v=2023-08-11T21:30:55.901Z"
		}
	}
}